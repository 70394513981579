import React, { useEffect, useRef, useState } from "react";
import CustomSearchBox from "../../Core/CommonV2/CustomSearchBox";
import CustomLayout from "../../Core/LayoutV2/CustomLayout";
import { Popover, Tooltip, Spin } from "antd";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import { useNavigate, useParams } from "react-router-dom";
import { PlusIconBold, PlusIconBoldBlack } from "../../Core/svgV2/PlusIcon";
import { CopyOutlineIcon } from "../../Core/svgV2/CopyIcon";
import ProjectDateModal from "./Modals/ProjectDateModal";
import { ProjectDropDown, DisplayWeeks } from "./TimeEstimatesReusable";
import EstimatesMissingModal from "./Modals/EstimatesMissingModal";
import { EditThreeIcon } from "../../Core/svgV2/Edit02Icon";
import { useGetProjectDetails } from "../../Core/redux/ReactQueryHooksV3/useProjectAPI";
import {
  useGetProjectWeeks,
  useGetTenantProjects,
} from "../../Core/redux/ReactQueryHooksV3/userTimeEstimatesAPI";
import { useDispatch } from "react-redux";
import {
  addAssignees,
  addProjectWeeks,
  deleteAssignees,
  deleteWeeks,
  editProjectDates,
  updateAssignees,
  updateWeeks,
} from "../../Core/redux/api/timeEstimatesAPI";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
import { setPendingTimeEstimates } from "../../Core/redux/slices/timeEstimates.slice";
import { useAppSelector } from "../../Core/redux/hooks";
import AlertIcon from "../../Core/svgV2/AlertIcon";
import { useQueryClient } from "react-query";
import { motion } from "framer-motion";
import { CrossIconSecondary } from "../../Core/svgV2/CrossIcon";
import { updateGlobalKey } from "../../Core/redux/slices/global.slice";
import dayjs from "dayjs";
import usePermission from "../../Core/hooks/usePermission";

const ProjectTimeEstimatesScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { projectId } = useParams();

  const { data: projectDetails, isLoading: isLoading1 } =
    useGetProjectDetails(projectId);
  const { data: weeksData, isLoading: isLoading2 } =
    useGetProjectWeeks(projectId);
  const { pendingTimeEstimates } = useAppSelector(
    (state) => state.timeEstimates
  );

  const [isActive, setIsActive] = useState(false);
  const [areButtonsVisible, setAreButtonsVisible] = useState(false);

  const [projectDateModal, setProjectDateModal] = useState(false);
  const [discardModal, setDiscardModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [dateChangeModalOne, setDateChangeModalOne] = useState(false);
  const [dateChangeModalTwo, setDateChangeModalTwo] = useState(false);
  const [dateChangeModalThree, setDateChangeModalThree] = useState(false);

  const [apiPayload, setApiPayload] = useState(null);

  const [projectStartDate, setProjectStartDate] = useState(null);
  const [projectEndDate, setProjectEndDate] = useState(null);

  const [weeksWithAssigneesAndNames, setWeeksWithAssigneesAndNames] = useState(
    []
  );
  const [activeWeek, setActiveWeek] = useState([]);
  const [newlyAddedAssignees, setNewlyAddedAssignees] = useState([]);
  const [weeksWithDetails, setWeeksWithDetails] = useState([]);

  const [deletedAssigneesPayload, setDeletedAssigneesPayload] = useState([]);
  const [overallTotalHours, setOverallTotalHours] = useState(0);
  const [search, setSearch] = useState("");

  const [firstWeekName, setFirstWeekName] = useState(null);
  const [lastWeekName, setLastWeekName] = useState(null);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [noOfPendingEstimates, setNoOfPendingEstimates] = useState(0);
  const [firstFewPendingEstimates, setFirstFewPendingEstimates] = useState([]);
  const scrollableDivRef = useRef(null);
  const weekRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const { hasPermission } = usePermission("1");
  const { tenantPermissionArray } = useAppSelector((state) => state.projects);

  const calculateOverallTotalHours = () => {
    let totalHours = 0;
    weeksWithAssigneesAndNames.forEach((week) => {
      week.assignees.forEach((assignee) => {
        const hours = parseInt(assignee.noOfHours, 10) || 0;
        const days = parseInt(assignee.noOfDays, 10) || 0;
        totalHours += hours * days;
      });
    });
    setOverallTotalHours(totalHours);
  };

  useEffect(() => {
    calculateOverallTotalHours();
  }, [weeksWithAssigneesAndNames]);

  useEffect(() => {
    // console.log(weeksWithAssigneesAndNames, "as we enter", weeksWithDetails);
    // Update count for each week in weeksWithAssigneesAndNames
    weeksWithAssigneesAndNames.forEach((week) => {
      const weekIndexInDetails = weeksWithDetails.findIndex(
        (detailWeek) =>
          detailWeek.startDate === week.startDate &&
          detailWeek.endDate === week.endDate
      );

      week["count"] = weekIndexInDetails !== -1 ? weekIndexInDetails + 1 : 1;
    });

    // Check if weeksWithAssigneesAndNames has fewer weeks than weeksWithDetails
    const hasFewerWeeks =
      weeksWithAssigneesAndNames?.length < weeksWithDetails?.length;

    // Check if the last week of weeksWithDetails is NOT in weeksWithAssigneesAndNames
    const lastWeekDetails = weeksWithDetails[weeksWithDetails.length - 1];
    const lastWeekExistsInAssignees = weeksWithAssigneesAndNames?.some(
      (week) =>
        week.startDate === lastWeekDetails?.startDate &&
        week.endDate === lastWeekDetails?.endDate
    );
    console.log(weeksWithAssigneesAndNames, "here is before", weeksWithDetails);
    // Set areButtonsVisible based on the conditions
    if (hasFewerWeeks && !lastWeekExistsInAssignees) {
      setAreButtonsVisible(true);
    } else {
      setAreButtonsVisible(false);
    }
  }, [weeksWithAssigneesAndNames, weeksWithDetails]);

  useEffect(() => {
    const { firstWeekName, lastWeekName } = calculateRemainingWeeks(
      weeksWithDetails,
      projectEndDate
    );

    setFirstWeekName(firstWeekName);
    setLastWeekName(lastWeekName);
  }, [projectId, weeksWithDetails]);

  useEffect(() => {
    if (projectDetails) {
      const startDate = new Date(projectDetails?.projectDetails2?.startDate);
      const utcStartDate = new Date(
        Date.UTC(
          startDate.getUTCFullYear(),
          startDate.getUTCMonth(),
          startDate.getUTCDate(),
          0,
          0,
          0
        )
      );
      setProjectStartDate(utcStartDate);

      const endDate = new Date(projectDetails?.projectDetails2?.endDate);
      const utcEndDate = new Date(
        Date.UTC(
          endDate.getUTCFullYear(),
          endDate.getUTCMonth(),
          endDate.getUTCDate(),
          0,
          0,
          0
        )
      );
      setProjectEndDate(utcEndDate);
    }
  }, [projectDetails]);

  useEffect(() => {
    createWeeksFromDates(projectStartDate, projectEndDate);
  }, [
    projectId,
    projectDetails,
    projectStartDate,
    projectEndDate,
    // weeksWithDetails,
  ]);

  useEffect(() => {
    if (weeksData && Array.isArray(weeksData) && weeksData.length > 0) {
      setWeeksWithAssigneesAndNames(weeksData);
    } else if (projectStartDate) {
      const newWeek = createDefaultWeek();
      setWeeksWithAssigneesAndNames([newWeek]);
    }
    console.log(weeksWithAssigneesAndNames, "createWeeksFromDates 1");
  }, [weeksData, projectStartDate]);

  useEffect(() => {
    const currentDate = new Date();
    const endDate = new Date(projectEndDate);

    const lastWeek = weeksWithAssigneesAndNames.reduce((latest, week) => {
      const weekEndDate = new Date(week.endDate);
      return weekEndDate > latest ? weekEndDate : latest;
    }, new Date(0));

    const shouldSetPending =
      endDate > lastWeek &&
      !(
        lastWeek >= currentDate &&
        lastWeek <= new Date(currentDate.setDate(currentDate.getDate() + 6))
      );

    dispatch(setPendingTimeEstimates(shouldSetPending));
  }, [projectEndDate, weeksWithAssigneesAndNames, dispatch]);

  const createWeeksFromDates = (projectStartDate, projectEndDate) => {
    if (
      !(projectStartDate instanceof Date) ||
      !(projectEndDate instanceof Date) ||
      projectStartDate > projectEndDate
    ) {
      console.error("Invalid startDate or endDate");
      return;
    }

    const weeks = [];
    const currentStartDate = new Date(projectStartDate);

    // Handle the first partial week if the start date is not a Monday
    if (currentStartDate.getDay() !== 1) {
      const partialWeekEndDate = new Date(currentStartDate);
      const daysUntilFriday = (5 - currentStartDate.getDay() + 7) % 7;
      partialWeekEndDate.setDate(
        currentStartDate.getDate() +
          Math.min(
            daysUntilFriday,
            (projectEndDate.getTime() - currentStartDate.getTime()) /
              (24 * 60 * 60 * 1000)
          )
      );

      weeks.push({
        weekName: `Week ${getWeekNumber(currentStartDate)} '${String(
          currentStartDate.getFullYear()
        ).slice(-2)}`,
        startDate: currentStartDate.toISOString().split("T")[0],
        endDate: partialWeekEndDate.toISOString().split("T")[0],
      });

      // Move to the next Monday
      currentStartDate.setDate(partialWeekEndDate.getDate() + 3);
    }

    // Align the start date to the next Monday if it's not already
    if (currentStartDate.getDay() !== 1) {
      const daysToNextMonday = (8 - currentStartDate.getDay()) % 7;
      currentStartDate.setDate(currentStartDate.getDate() + daysToNextMonday);
    }

    while (currentStartDate <= projectEndDate) {
      // Calculate week end date (Friday)
      const endDate = new Date(currentStartDate);
      endDate.setDate(currentStartDate.getDate() + 4);

      if (endDate > projectEndDate) {
        endDate.setTime(projectEndDate.getTime());
      }

      const weekNumber = getWeekNumber(currentStartDate);
      const yearShort = `'${String(endDate.getFullYear()).slice(-2)}`;
      const weekName = `Week ${weekNumber} ${yearShort}`;

      weeks.push({
        weekName,
        startDate: currentStartDate.toISOString().split("T")[0],
        endDate: endDate.toISOString().split("T")[0],
      });

      // Move to next week's start (Monday)
      currentStartDate.setDate(currentStartDate.getDate() + 7);
    }

    console.log(weeks, "createWeeksFromDates");
    setWeeksWithDetails(weeks);
  };

  const hasPermission29 = tenantPermissionArray?.[projectId]?.includes("29");
  const hasPermission30 = tenantPermissionArray?.[projectId]?.includes("30");
  const hasPermission32 = tenantPermissionArray?.[projectId]?.includes("32");

  const getWeekNumber = (date) => {
    const startOfYear = dayjs(new Date(date.getFullYear(), 0, 1)).startOf(
      "day"
    );
    const weekStart = startOfYear.day(1); // First Monday of the year
    const diffDays = date.valueOf() - weekStart.valueOf();
    let weekNumber = Math.floor(diffDays / (7 * 24 * 60 * 60 * 1000)) + 1;

    if (weekNumber > 52) {
      weekNumber = 1;
    }

    return weekNumber;
  };

  const handleDeletedAssignees = (payload) => {
    setDeletedAssigneesPayload((prevPayload) => [...prevPayload, ...payload]);
  };

  const updateWeeksWithNewDates = (
    newStartDate,
    newEndDate,
    prevWeeks: any[]
  ) => {
    const newWeeks = [];
    const updatedWeeks = [];
    const deletedWeeks = [];
    const neewWeeksPayload = [];

    const newStartDateObj = new Date(newStartDate);
    const newEndDateObj = new Date(newEndDate);

    const firstWeekEndDate = new Date(newStartDateObj);
    firstWeekEndDate.setDate(
      firstWeekEndDate.getDate() + (5 - firstWeekEndDate.getDay())
    );

    // Adding year suffix to the week
    const firstYearSuffix = `'${String(newStartDateObj.getFullYear()).slice(
      -2
    )}`;
    newWeeks.push({
      id: `temp-${Date.now()}`,
      week: `Week ${getWeekNumber(newStartDateObj)} ${firstYearSuffix}`,
      startDate: newStartDateObj.toISOString(),
      endDate: firstWeekEndDate.toISOString(),
      totalHours: null,
      assignees: [],
    });

    const currentStartDate = new Date(firstWeekEndDate);
    currentStartDate.setDate(currentStartDate.getDate() + 3);

    while (currentStartDate < newEndDateObj) {
      const weekEndDate = new Date(currentStartDate);
      weekEndDate.setDate(currentStartDate.getDate() + 4);

      if (weekEndDate > newEndDateObj) {
        weekEndDate.setTime(newEndDateObj.getTime());
      }

      // Adding year suffix to each subsequent week
      const yearSuffix = `'${String(currentStartDate.getFullYear()).slice(-2)}`;
      newWeeks.push({
        id: `temp-${Date.now()}`,
        week: `Week ${getWeekNumber(currentStartDate)} ${yearSuffix}`,
        startDate: currentStartDate.toISOString(),
        endDate: weekEndDate.toISOString(),
        totalHours: null,
        assignees: [],
      });

      currentStartDate.setDate(currentStartDate.getDate() + 7);
    }

    const lastWeek = newWeeks[newWeeks.length - 1];
    lastWeek.endDate = newEndDateObj.toISOString();

    const prevWeeksMap = new Map(prevWeeks?.map((week) => [week.week, week]));

    const lastPrevWeekNumber = Math.max(
      ...prevWeeks.map((week) => parseInt(week.week.split(" ")[1]))
    );

    const filteredNewWeeks = newWeeks.filter((week) => {
      const weekNumber = parseInt(week.week.split(" ")[1]);
      return !prevWeeksMap.has(week.week) && weekNumber <= lastPrevWeekNumber;
    });

    newWeeks.forEach((week) => {
      if (prevWeeksMap.has(week.week)) {
        const prevWeek = prevWeeksMap.get(week.week);

        if (!prevWeek.id.startsWith("temp")) {
          if (
            prevWeek.startDate !== week.startDate ||
            prevWeek.endDate !== week.endDate
          ) {
            updatedWeeks.push({
              ...prevWeek,
              startDate: week.startDate,
              endDate: week.endDate,
            });
          }
          prevWeeksMap.delete(week.week);
        }
      }
    });

    for (const week of Array.from(prevWeeksMap.values())) {
      if (!week.id.startsWith("temp")) {
        deletedWeeks.push(week.id);
      }
    }

    neewWeeksPayload.push(...filteredNewWeeks, ...updatedWeeks);

    const payload = {
      projectId: projectId,
      projectStartDate: newStartDate,
      projectEndDate: newEndDate,
      newWeeks: filteredNewWeeks,
      updatedWeeks: updatedWeeks,
      deletedWeeks: deletedWeeks,
    };

    return payload;
  };

  const handleDateSave = (newStartDate, newEndDate) => {
    const adjustedStartDate = new Date(newStartDate);
    const adjustedEndDate = new Date(newEndDate);

    setTempStartDate(adjustedStartDate);
    setTempEndDate(adjustedEndDate);

    let payload = updateWeeksWithNewDates(
      adjustedStartDate,
      adjustedEndDate,
      weeksWithAssigneesAndNames
    );

    // Adjust existing weeks based on the new start and end dates
    const adjustedWeeks = adjustWeeksForNewDates(
      adjustedStartDate,
      adjustedEndDate,
      payload.updatedWeeks // Pass updatedWeeks from payload for date adjustments
    );

    // Update the payload to reflect adjusted weeks
    payload = { ...payload, updatedWeeks: adjustedWeeks };

    setApiPayload(payload);

    if (payload.deletedWeeks.length > 0) {
      setDateChangeModalTwo(true);
    } else {
      setDateChangeModalOne(true);
    }
    queryClient.refetchQueries(["tenantInsights"], {
      active: false,
    });
  };

  const handleApiCalls = async (payload, shouldDelete) => {
    try {
      const numericProjectId = Number(payload.projectId);

      // Step 1: Edit the project start and end dates
      await dispatch(
        editProjectDates({
          projectId: numericProjectId,
          updateObj: {
            startDate: payload.projectStartDate,
            endDate: payload.projectEndDate,
          },
        }) as any
      ).unwrap();

      // Step 2: Use `updateWeeks` to update existing weeks in the project
      if (payload.updatedWeeks.length > 0) {
        await dispatch(
          updateWeeks({
            projectId: numericProjectId,
            existingWeeks: payload.updatedWeeks.map((week) => ({
              id: week.id,
              week: week.week,
              startDate: week.startDate,
              endDate: week.endDate,
              assignees: week.assignees || [],
            })),
          }) as any
        ).unwrap();
      }

      // Step 3: Handle deleted weeks, if applicable
      if (shouldDelete && payload.deletedWeeks.length > 0) {
        await dispatch(
          deleteWeeks({
            projectId: numericProjectId,
            deletedWeeks: payload.deletedWeeks.map((weekId) => ({
              id: weekId,
            })),
          }) as any
        ).unwrap();
      } else {
        setDeletedAssigneesPayload(payload.deletedWeeks);
      }

      // Step 4: Add new weeks if valid
      if (payload.newWeeks.length > 0) {
        const validWeeks = payload.newWeeks.filter(
          (week) =>
            new Date(week.startDate) >= new Date(payload.projectStartDate) &&
            new Date(week.endDate) <= new Date(payload.projectEndDate)
        );

        if (validWeeks.length > 0) {
          await dispatch(
            addProjectWeeks({
              projectId: numericProjectId,
              weeks: validWeeks,
            }) as any
          ).unwrap();
        } else {
          console.log("No valid weeks to add based on project start date.");
        }
      }

      // Invalidate cache and update dashboard
      queryClient.invalidateQueries("projectWeeks");
      queryClient.invalidateQueries("projectDetails");

      dispatch(
        updateDashboard({
          key: "alertPopupModal",
          value: {
            visible: true,
            data: {
              title: `Project dates modified`,
              subtitle: "",
              description: `You can add estimates for the modified weeks`,
            },
          },
        })
      );
    } catch (error) {
      console.error("Error during API calls:", error);
    }
  };

  const adjustWeeksForNewDates = (newStartDate, newEndDate, weeks) => {
    const adjustedWeeks = [...weeks];

    // Convert new start and end dates to Date objects for comparison
    const newStart = new Date(newStartDate);
    const newEnd = new Date(newEndDate);

    if (adjustedWeeks.length === 0) return adjustedWeeks; // If no weeks, return as is

    const firstWeek = adjustedWeeks[0];
    const lastWeek = adjustedWeeks[adjustedWeeks.length - 1];

    const firstWeekStart = new Date(firstWeek.startDate);
    const lastWeekEnd = new Date(lastWeek.endDate);

    // Adjust the start date of the first week if newStartDate is earlier
    if (newStart < firstWeekStart) {
      // Limit adjustment to Monday if newStart is before current week's Monday
      const mondayOfFirstWeek = getMondayOfWeek(firstWeekStart);
      firstWeek.startDate =
        newStart < mondayOfFirstWeek
          ? mondayOfFirstWeek.toISOString()
          : newStart.toISOString();
    }

    // Adjust the end date of the last week if newEndDate is later
    if (newEnd > lastWeekEnd) {
      // Limit adjustment to Friday if newEnd is after current week's Friday
      const fridayOfLastWeek = getFridayOfWeek(lastWeekEnd);
      lastWeek.endDate =
        newEnd > fridayOfLastWeek
          ? fridayOfLastWeek.toISOString()
          : newEnd.toISOString();
    }

    const updatedWeeks = [];
    updatedWeeks.push(firstWeek);
    updatedWeeks.push(lastWeek);

    return updatedWeeks;
  };

  // Helper function to get the Monday of the week of a given date
  const getMondayOfWeek = (date) => {
    const day = date.getDay();
    const diff = day === 0 ? -6 : 1 - day; // Adjust for Sunday being 0
    const monday = new Date(date);
    monday.setDate(date.getDate() + diff);
    return monday;
  };

  // Helper function to get the Friday of the week of a given date
  const getFridayOfWeek = (date) => {
    const day = date.getDay();
    const diff = day <= 5 ? 5 - day : -2; // Adjust for weekend overflow
    const friday = new Date(date);
    friday.setDate(date.getDate() + diff);
    return friday;
  };

  const addAssigneeToWeek = (weekId, updatedAssignees) => {
    setIsEdited(true);
    setWeeksWithAssigneesAndNames((prevWeeks) => {
      return prevWeeks.map((week) => {
        if (week.id === weekId) {
          const newAssignees = updatedAssignees.filter((assignee) =>
            assignee.id.startsWith("temp-")
          );

          const formattedNewAssignees = newAssignees.map((assignee) => ({
            userId: parseInt(assignee.id.replace("temp-", "")),
            days: assignee.noOfDays,
            hours: assignee.noOfHours,
          }));

          setNewlyAddedAssignees((prev) => [
            ...prev,
            ...formattedNewAssignees.map((a) => ({
              projectId: projectId,
              weekId: week.id,
              assignees: [a],
            })),
          ]);

          return { ...week, assignees: updatedAssignees };
        }
        return week;
      });
    });
  };

  const createDefaultWeek = () => {
    if (!projectStartDate) {
      return null;
    }

    const startDate = new Date(projectStartDate);
    const startDay = startDate.getDay();
    const adjustedStartDate = new Date(projectStartDate);

    if (startDay === 6) {
      adjustedStartDate.setDate(adjustedStartDate.getDate() + 2);
    } else if (startDay === 0) {
      adjustedStartDate.setDate(adjustedStartDate.getDate() + 1);
    }

    const weekNumber = getWeekNumber(adjustedStartDate);

    // Get the last two digits of the year
    const yearSuffix = `'${String(startDate.getFullYear()).slice(-2)}`;

    const endDate = new Date(projectStartDate);
    const dayOfWeek = projectStartDate.getDay();
    const daysUntilFriday = (5 - dayOfWeek + 7) % 7;

    endDate.setDate(projectStartDate.getDate() + daysUntilFriday);

    // Limit endDate to the projectEndDate if necessary
    if (endDate > projectEndDate) {
      endDate.setTime(projectEndDate.getTime());
    }

    return {
      id: `temp-${Date.now()}`,
      week: `Week ${weekNumber} ${yearSuffix}`, // Week number with year suffix
      startDate: adjustedStartDate.toISOString(),
      endDate: endDate.toISOString(),
      totalHours: null,
      assignees: [],
    };
  };

  const addWeek = () => {
    const lastWeek =
      weeksWithAssigneesAndNames[weeksWithAssigneesAndNames.length - 1];
    const lastWeekEndDate = new Date(lastWeek.endDate);
    // eslint-disable-next-line prefer-const
    let newStartDate = new Date(lastWeekEndDate);

    // Check if the last week ended on Friday or not
    if (lastWeekEndDate.getDay() === 5) {
      // If it ended on Friday, move to the next Monday
      newStartDate.setDate(newStartDate.getDate() + 3);
    } else {
      // If it ended before Friday, start the next day (Saturday/Sunday)
      newStartDate.setDate(newStartDate.getDate() + 1);
    }

    // Stop adding if the new start date is past the project end date
    if (newStartDate > projectEndDate) {
      setAreButtonsVisible(false);
      console.warn("disabled the add weeks and add and duplicates");
      return;
    }

    const newEndDate = new Date(newStartDate);
    const daysUntilFriday = (5 - newStartDate.getDay() + 7) % 7;
    newEndDate.setDate(newStartDate.getDate() + daysUntilFriday);

    // Ensure the newEndDate does not exceed the project end date
    if (newEndDate > projectEndDate) {
      newEndDate.setTime(projectEndDate.getTime());
    }

    // // Handle year-end if the new week crosses into the next year
    // if (newStartDate.getFullYear() < newEndDate.getFullYear()) {
    //   newEndDate.setFullYear(newStartDate.getFullYear());
    //   newEndDate.setMonth(11); // December
    //   newEndDate.setDate(31); // End of the year

    //   if (newEndDate.getDay() === 6) {
    //     newEndDate.setDate(29); // If 31st is a Saturday, set to the previous Friday
    //   } else if (newEndDate.getDay() === 0) {
    //     newEndDate.setDate(30); // If 31st is a Sunday, set to the previous Friday
    //   }
    // }

    const newWeekNumber = getWeekNumber(newStartDate);
    const yearSuffix = `'${String(newEndDate.getFullYear()).slice(-2)}`; // Last 2 digits of the year

    const newWeek = {
      id: `temp-${Date.now()}`,
      week: `Week ${newWeekNumber} ${yearSuffix}`, // Week number with year suffix
      startDate: newStartDate.toISOString().split("T")[0],
      endDate: newEndDate.toISOString().split("T")[0],
      totalHours: null,
      assignees: [],
    };

    setWeeksWithAssigneesAndNames((prevWeeks) => [...prevWeeks, newWeek]);

    const nextStartDate = new Date(newEndDate);
    nextStartDate.setDate(nextStartDate.getDate() + 3);
    setIsEdited(true);

    if (newEndDate >= nextStartDate || nextStartDate > projectEndDate) {
      setAreButtonsVisible(false);
      console.warn(
        "disabled the add weeks and add and duplicates due to date overlap"
      );
      return;
    }
  };

  const separateWeeks = () => {
    const existingWeeks = weeksWithAssigneesAndNames.filter(
      (week) => !week.id.startsWith("temp-")
    );
    const newWeeks = weeksWithAssigneesAndNames.filter((week) =>
      week.id.startsWith("temp-")
    );

    return { existingWeeks, newWeeks };
  };

  const createPayloadForNewAssigneesAndRemoveFromWeeks = () => {
    const existingWeeks = weeksWithAssigneesAndNames.filter(
      (week) => !week.id.startsWith("temp-")
    );
    const newAssigneesPayload = [];
    const presentAssigneesPayload = [];

    const updatedWeeks = existingWeeks.map((week) => {
      const newAssignees = week.assignees
        .filter((assignee) => assignee.id.startsWith("temp-"))
        .map((assignee) => ({
          userId: assignee.assigneeId,
          noOfDays: assignee.noOfDays,
          noOfHours: assignee.noOfHours,
        }));

      const presentAssignees = week.assignees
        .filter((assignee) => !assignee.id.startsWith("temp-"))
        .map((assignee) => ({
          id: assignee.id,
          userId: assignee.assigneeId,
          noOfDays: assignee.noOfDays,
          noOfHours: assignee.noOfHours,
        }));

      const updatedAssignees = week.assignees.filter(
        (assignee) => !assignee.id.startsWith("temp-")
      );

      if (newAssignees.length > 0) {
        newAssigneesPayload.push({
          projectId: projectId,
          weekId: week.id,
          assignees: newAssignees,
        });
      }

      if (presentAssignees.length > 0) {
        presentAssigneesPayload.push({
          projectId: projectId,
          weekId: week.id,
          assignees: presentAssignees,
        });
      }

      return {
        ...week,
        assignees: updatedAssignees,
      };
    });

    return { newAssigneesPayload, presentAssigneesPayload, updatedWeeks };
  };

  const handleOpenModal = () => {
    setDiscardModal(true);
  };

  const handleSave = () => {
    let missingWeeksCount = 0;
    const pendingWeeks = [];

    weeksWithDetails.forEach((weekDetail) => {
      const matchingWeek = weeksWithAssigneesAndNames.find(
        (week) => week?.week === weekDetail?.weekName
      );

      // Check if week is missing or invalid (missing required assignee hours and days)
      const isWeekInvalid =
        !matchingWeek ||
        !matchingWeek.assignees.some(
          (assignee) =>
            assignee?.noOfDays !== null && assignee?.noOfHours !== null
        );

      if (isWeekInvalid) {
        missingWeeksCount += 1;
        if (pendingWeeks.length < 4) {
          pendingWeeks.push(weekDetail.weekName);
        }
      }
    });

    setNoOfPendingEstimates(missingWeeksCount);
    setFirstFewPendingEstimates(pendingWeeks);

    if (missingWeeksCount > 0) {
      handleOpenModal();
    } else {
      saveChanges();
    }
  };

  // Helper function to check if weeks are consecutive
  const areWeeksConsecutive = (weeks) => {
    for (let i = 1; i < weeks.length; i++) {
      const currentWeek = parseInt(weeks[i].match(/\d+/)[0]);
      const previousWeek = parseInt(weeks[i - 1].match(/\d+/)[0]);
      if (currentWeek !== previousWeek + 1) return false;
    }
    return true;
  };

  const saveChanges = async () => {
    const { existingWeeks, newWeeks } = separateWeeks();
    const { newAssigneesPayload, presentAssigneesPayload, updatedWeeks } =
      createPayloadForNewAssigneesAndRemoveFromWeeks();

    // console.log(
    //   newAssigneesPayload,
    //   "new weeks one deletedAssigneesPayload=>",
    //   deletedAssigneesPayload,
    //   "presentAssigneesPayload =>",
    //   presentAssigneesPayload,
    //   "presentAssigneesPayload=>",
    //   presentAssigneesPayload,
    //   "is this ?=>",
    //   newWeeks
    // );

    // Validation function for newAssigneesPayload
    const hasInvalidAssignees =
      newAssigneesPayload.some((week) =>
        week?.assignees?.some(
          (assignee) =>
            assignee?.noOfHours === null || assignee?.noOfDays === null
        )
      ) ||
      newWeeks.some((week) =>
        week?.assignees?.some(
          (assignee) =>
            assignee?.noOfHours === null || assignee?.noOfDays === null
        )
      );

    if (hasInvalidAssignees) {
      dispatch(
        updateGlobalKey({
          key: "error",
          value: {
            message:
              "Cannot save changes. Some assignees have missing hours or days.",
          },
        })
      );
      dispatch(
        updateGlobalKey({
          key: "status",
          value: "rejected",
        })
      );
      setTimeout(() => {
        dispatch(
          updateGlobalKey({
            key: "status",
            value: "fulfilled",
          })
        );
      }, 3000);
      return;
    }

    try {
      if (deletedAssigneesPayload.length > 0) {
        await dispatch(
          deleteAssignees(deletedAssigneesPayload) as any
        ).unwrap();
      }
      if (newWeeks.length > 0) {
        const numericProjectId = Number(projectId);
        await dispatch(
          addProjectWeeks({
            projectId: numericProjectId,
            weeks: newWeeks,
          }) as any
        ).unwrap();
      }
      if (newAssigneesPayload.length > 0) {
        await dispatch(addAssignees(newAssigneesPayload) as any).unwrap();
      }

      if (presentAssigneesPayload.length > 0) {
        await dispatch(
          updateAssignees(presentAssigneesPayload) as any
        ).unwrap();
      }

      queryClient.invalidateQueries("projectWeeks");

      setDiscardModal(false);
      navigate(`/time-estimates/`);

      dispatch(
        updateDashboard({
          key: "alertPopupModal",
          value: {
            visible: true,
            data: {
              title: `Saved your time estimates`,
              subtitle: "",
              description: `Saved changes to ${projectDetails?.projectDetails?.title}`,
            },
          },
        })
      );
      queryClient.invalidateQueries("projectDetails");
      queryClient.invalidateQueries("tenantProjects");
    } catch (error) {
      console.error("Error during saveChanges:", error);
    }
  };

  const addAndDuplicate = () => {
    const lastWeek =
      weeksWithAssigneesAndNames[weeksWithAssigneesAndNames.length - 1];

    const lastWeekEndDate = new Date(lastWeek.endDate);
    // eslint-disable-next-line prefer-const
    let newStartDate = new Date(lastWeekEndDate);

    // Check if last week ended on Friday
    if (lastWeekEndDate.getDay() === 5) {
      // If it ended on Friday, move to the next Monday
      newStartDate.setDate(newStartDate.getDate() + 3);
    } else {
      // If it ended before Friday (any other day), start the next day
      newStartDate.setDate(newStartDate.getDate() + 1);
    }

    if (newStartDate > projectEndDate) {
      console.warn(
        "Cannot add a new week: new start date exceeds project end date."
      );
      return;
    }

    const newEndDate = new Date(newStartDate);
    const daysUntilFriday = (5 - newStartDate.getDay() + 7) % 7;
    newEndDate.setDate(newStartDate.getDate() + daysUntilFriday);

    if (newEndDate > projectEndDate) {
      newEndDate.setTime(projectEndDate.getTime());
    }

    // // Handle the year-end scenario
    // const currentYear = newStartDate.getFullYear();
    // if (newEndDate.getFullYear() > currentYear) {
    //   newEndDate.setFullYear(currentYear);
    //   newEndDate.setMonth(11); // December
    //   newEndDate.setDate(31);

    //   if (newEndDate.getDay() === 6) {
    //     newEndDate.setDate(29); // If 31st is a Saturday, set to the previous Friday
    //   } else if (newEndDate.getDay() === 0) {
    //     newEndDate.setDate(30); // If 31st is a Sunday, set to the previous Friday
    //   }
    // }

    const availableDays =
      (newEndDate.getTime() - newStartDate.getTime()) / (1000 * 60 * 60 * 24) +
      1;

    const newWeekNumber = getWeekNumber(newStartDate);
    const yearSuffix = `'${String(newEndDate.getFullYear()).slice(-2)}`; // Extract last 2 digits of the year

    const newWeek = {
      id: `temp-${Date.now()}`,
      week: `Week ${newWeekNumber} ${yearSuffix}`, // Include year suffix in week name
      startDate: newStartDate.toISOString().split("T")[0],
      endDate: newEndDate.toISOString().split("T")[0],
      totalHours: lastWeek.totalHours,
      assignees: lastWeek.assignees.map((assignee) => ({
        ...assignee,
        noOfDays: Math.min(assignee.noOfDays, availableDays),
      })),
    };

    setWeeksWithAssigneesAndNames([...weeksWithAssigneesAndNames, newWeek]);

    const nextStartDate = new Date(newEndDate);
    nextStartDate.setDate(nextStartDate.getDate() + 3);
    setIsEdited(true);

    if (newEndDate >= nextStartDate || nextStartDate > projectEndDate) {
      setAreButtonsVisible(false);
      console.warn(
        "disabled the add weeks and add and duplicates due to date overlap"
      );
      return;
    }
  };

  const calculateRemainingWeeks = (weeksWithDetails, projectEndDate) => {
    const currentDate = new Date();
    console.log(weeksWithDetails, "weeksWithDetails");
    const endDate = new Date(projectEndDate);

    const lastWeek = weeksWithAssigneesAndNames.reduce((latest, week) => {
      const weekEndDate = new Date(week.endDate);
      return weekEndDate > latest ? weekEndDate : latest;
    }, new Date(0));

    const remainingWeeks = weeksWithDetails.filter((week) => {
      const weekEndDate = new Date(week.endDate);
      const isInRange = weekEndDate > lastWeek && weekEndDate <= endDate;
      return isInRange;
    });

    // Helper function to get the formatted week name with the year
    const formatWeekWithYear = (weekName, weekEndDate) => {
      const year = new Date(weekEndDate).getFullYear();
      const shortYear = String(year).slice(-2);
      return `${weekName}`;
    };

    const firstWeekName =
      remainingWeeks.length > 0
        ? formatWeekWithYear(
            remainingWeeks[0].weekName,
            remainingWeeks[0].endDate
          )
        : null;

    const lastWeekName =
      remainingWeeks.length > 0
        ? formatWeekWithYear(
            remainingWeeks[remainingWeeks.length - 1].weekName,
            remainingWeeks[remainingWeeks.length - 1].endDate
          )
        : null;

    return { firstWeekName, lastWeekName };
  };

  const switchWeeks = (selectedWeek) => {
    const selectedWeekIndex = weeksWithAssigneesAndNames.findIndex(
      (week) =>
        new Date(week.startDate).getTime() ===
          new Date(selectedWeek.startDate).getTime() &&
        new Date(week.endDate).getTime() ===
          new Date(selectedWeek.endDate).getTime()
    );

    if (selectedWeekIndex === -1) {
      const yearSuffix = `'${String(
        new Date(selectedWeek.endDate).getFullYear()
      ).slice(-2)}`;
      const weekNumber = selectedWeek.weekName.split(" ")[1];

      const newWeek = {
        id: `temp-${Date.now()}`, // Generate a temporary ID
        week: `Week ${weekNumber} ${yearSuffix}`,
        startDate: selectedWeek.startDate,
        endDate: selectedWeek.endDate,
        totalHours: null,
        assignees: [],
      };

      // Append the new week to weeksWithAssigneesAndNames
      setWeeksWithAssigneesAndNames((prevWeeks) => [...prevWeeks, newWeek]);
      setIsEdited(true);

      // Use setTimeout to scroll to the new week element after a short delay
      setTimeout(() => {
        scrollToWeekElement(newWeek.id); // Scroll to the new week element
      }, 100); // Adjust the delay as necessary
    } else {
      // If it exists, just scroll to the existing week
      setTimeout(() => {
        scrollToWeekElement(weeksWithAssigneesAndNames[selectedWeekIndex].id);
      }, 100); // Adjust the delay as necessary
    }
  };

  const scrollToWeekElement = (weekId) => {
    const weekElement = document.getElementById(weekId);
    if (weekElement) {
      weekElement.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      console.warn(`Week element with ID ${weekId} not found.`);
    }
  };

  const { data: projectsData, isLoading: isLoadingProject } =
    useGetTenantProjects();
  const projectList = projectsData?.projects;

  const filteredProjects = projectList?.filter((project) =>
    project.title.toLowerCase().includes(search.toLowerCase())
  );

  const getTitle = () => {
    if (
      projectDetails &&
      projectDetails.projectDetails &&
      projectDetails.projectDetails.title
    ) {
      return projectDetails?.projectDetails?.title;
    } else {
      return "All Projects";
    }
  };

  const scrollToBottom = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop =
        scrollableDivRef.current.scrollHeight;
    }
  };

  return (
    <CustomLayout
      page="time-estimates"
      title={
        <div className="flex gap-x-1.5 items-center ">
          <span className="out-300-14 text-gray-500">Time Estimates</span>{" "}
          <span className="out-300-14 text-gray-500">/</span>
          <Popover
            open={isActive}
            placement="bottomLeft"
            onOpenChange={(open) => {
              setSearch("");
              setIsActive(open);
            }}
            content={
              <div className="w-[400px] shadow-md rounded-md bg-white border border-gray-100">
                <div className="p-2 px-4">
                  <CustomSearchBox
                    placeholder="Search Projects"
                    className={`${
                      isFocused ? "border-[#2E90FA]" : "border-gray-300"
                    }`}
                    search={search}
                    setSearch={setSearch}
                    suffix={
                      search ? (
                        <span
                          onClick={() => setSearch("")}
                          className="cursor-pointer"
                        >
                          <CrossIconSecondary />
                        </span>
                      ) : null
                    }
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                  />
                </div>
                <div className="flex h-[255px] flex-col items-start self-stretch">
                  <div className="w-full max-h-full overflow-y-auto">
                    {filteredProjects?.length ? (
                      filteredProjects?.map((project) => (
                        <ProjectDropDown
                          key={project.id}
                          project={project}
                          onSelect={() => setIsActive(false)}
                        />
                      ))
                    ) : (
                      <div className="p-4 text-center text-gray-500">
                        No projects found
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className="w-full py-3 px-4 flex justify-between items-center"
                  style={{
                    borderTop: "1px solid var(--Gray-200, #DDE5ED)",
                    background: "var(--White, #F9FBFE)",
                  }}
                >
                  <div
                    className="text-primary-600 font-outfit text-sm font-medium leading-5 cursor-pointer"
                    onClick={() => navigate(`/time-estimates/`)}
                  >
                    View all projects
                  </div>
                </div>
              </div>
            }
            arrow={false}
            trigger={["click"]}
          >
            <span
              className="flex gap-1 items-center cursor-pointer"
              onClick={() => setIsActive(!isActive)}
            >
              <span className="out-500-14 text-black ">{getTitle()}</span>{" "}
              <ChevronIcon
                className="cursor-pointer text-black"
                style={{
                  transition: "transform 0.3s ease-in-out 0s",
                  transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </span>
          </Popover>
        </div>
      }
    >
      <Spin spinning={isLoading1 || isLoading2}>
        <div className="w-full h-[54px] flex-shrink-0">
          <div className="w-full h-[54px] flex-shrink-0 border-b border-[#DDE5ED] bg-[#F9FBFE] flex items-center justify-between px-[18px]">
            <div className="flex items-center gap-[8px] mt-[9px] mb-[9px]">
              <div
                className="group flex items-center rounded-[8px] hover:bg-gray-100 cursor-pointer"
                role="button"
                onClick={() => navigate(`/time-estimates/`)}
              >
                <div className="flex h-[30px] justify-center py-[3px] px-2 gap-x-1 rounded cursor-pointer border border-transparent hover:border-[#F5F5F5]">
                  <ChevronIcon
                    className="text-[#292927] group-hover:text-gray-500 transition-all duration-300"
                    style={{
                      width: "20px",
                      height: "20px",
                      transform: "rotate(90deg)",
                    }}
                  />
                  <div
                    className="text-[#292927] text-sm font-medium leading-[20px] font-outfit group-hover:text-gray-500 
        transition-all duration-300"
                  >
                    {getTitle()}
                  </div>
                  <div
                    className="absolute bottom-0 left-0 right-0 h-[2px] bg-[#F5F5F5] opacity-0 group-hover:opacity-100
        transition-all duration-300 transform group-hover:translate-y-[-4px]"
                  />
                </div>
              </div>

              <div className="flex items-center gap-[8px]">
                <div className="flex w-[75px] justify-end items-center">
                  <div
                    className={`flex px-2 py-0 justify-center items-center gap-1 flex-1 rounded-full ${
                      dayjs(projectDetails?.projectDetails?.startDate).isAfter(
                        dayjs()
                      ) && !projectDetails?.projectDetails?.isEnded
                        ? "bg-[#DDE5ED]" // Upcoming
                        : projectDetails?.projectDetails?.isEnded
                        ? "bg-[#D1FFD1]" // Completed
                        : "bg-[#D1E9FF]" // Ongoing
                    }`}
                  >
                    <div
                      className={`text-${
                        dayjs(
                          projectDetails?.projectDetails?.startDate
                        ).isAfter(dayjs()) &&
                        !projectDetails?.projectDetails?.isEnded
                          ? "[#667085]" // Upcoming text color
                          : projectDetails?.projectDetails?.isEnded
                          ? "[#4CAF50]" // Completed text color
                          : "[#165ABF]" // Ongoing text color
                      } text-[12px] font-medium leading-[18px] text-center font-outfit`}
                    >
                      {dayjs(projectDetails?.projectDetails?.startDate).isAfter(
                        dayjs()
                      ) && !projectDetails?.projectDetails?.isEnded
                        ? "Upcoming"
                        : projectDetails?.projectDetails?.isEnded
                        ? "Completed"
                        : "Ongoing"}
                    </div>
                  </div>
                </div>

                <div className="text-[#667085] text-[10px] font-normal leading-normal font-outfit">
                  {weeksWithDetails.length} weeks
                </div>
                <div className="w-[1px] h-[8px] bg-[#C0CBD7]"></div>
                <div className="text-[#667085] text-[10px] font-normal leading-normal font-outfit">
                  {projectStartDate?.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })}
                  {" - "}
                  {projectEndDate?.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })}
                </div>
                {hasPermission29 && (
                  <div
                    className="flex w-[14px] h-[14px] justify-center items-center"
                    role="button"
                    // onClick={() => setProjectDateModal(true)}
                  >
                    <Tooltip
                      title={"Edit dates"}
                      placement="bottom"
                      style={{
                        display: "flex",
                        padding: "4px 8px",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        alignSelf: "stretch",
                        borderRadius: "4px",
                        background: "#000000",
                        color: "#ffffff",
                      }}
                    >
                      <EditThreeIcon
                        style={{ width: "14px", height: "14px" }}
                        onClick={() => setProjectDateModal(true)}
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
            {/* Buttons */}
            <div className="flex items-center gap-[8px] ml-auto">
              {!projectDetails?.projectDetails2?.hasTimeEstimates &&
              overallTotalHours === 0 ? (
                <div className="flex items-center">
                  <Tooltip
                    title={"Estimates are pending"}
                    placement="bottom"
                    style={{
                      display: "flex",
                      padding: "4px 8px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      alignSelf: "stretch",
                      borderRadius: "4px",
                      background: "#000000",
                      color: "#ffffff",
                    }}
                  >
                    <AlertIcon height="14px" width="14px" />
                  </Tooltip>
                </div>
              ) : null}

              <div className="relative flex items-center">
                {overallTotalHours > 0 && (
                  <motion.div
                    className="absolute right-0 z-[1] overflow-hidden flex gap-2"
                    initial={{ x: 0, opacity: 0 }}
                    animate={{ x: hasPermission29 ? -60 : -40, opacity: 1 }}
                    transition={{ duration: 2.5 }}
                  >
                    {!projectDetails?.projectDetails2?.hasTimeEstimates && (
                      <div className="flex items-center">
                        <Tooltip
                          title={"Estimates are pending"}
                          placement="bottom"
                          style={{
                            display: "flex",
                            padding: "4px 8px",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            alignSelf: "stretch",
                            borderRadius: "4px",
                            background: "#000000",
                            color: "#ffffff",
                          }}
                        >
                          <AlertIcon height="14px" width="14px" />
                        </Tooltip>
                      </div>
                    )}
                    {overallTotalHours > 0 && (
                      <div className=" rounded-xl bg-[#D1E9FF] cursor-default flex p-[4px_8px] items-center gap-2">
                        <p className="text-[#101828] text-center text-xs font-medium leading-[18px] font-outfit whitespace-nowrap">
                          Total estimated hours
                        </p>
                        <p className="text-gray900 text-center text-xs font-medium leading-[18px] font-outfit">
                          {overallTotalHours}h
                        </p>
                      </div>
                    )}
                  </motion.div>
                )}

                {/* Save Button */}
                {(hasPermission29 || hasPermission30 || hasPermission32) && (
                  <div className="flex items-start rounded-[8px]">
                    <div
                      className={`relative z-[1] flex p-[5px_10px] justify-center items-center gap-[8px] rounded-[4px] border ${
                        isEdited
                          ? "border-[#165ABF] bg-[#165ABF] text-white hover:bg-[#0E4DA3] cursor-pointer"
                          : "border-gray-300 bg-gray-200 text-[#344054]"
                      }`}
                      onClick={() => {
                        if (isEdited) {
                          handleSave();
                        }
                      }}
                    >
                      <p className="font-outfit text-sm font-medium leading-sm">
                        Save
                      </p>
                    </div>
                  </div>
                )}
              </div>

              {areButtonsVisible && hasPermission29 && (
                <div className="flex items-start rounded-md">
                  <div
                    className="flex p-1.5 justify-center items-center gap-2 rounded-md border border-[#EFF8FF] bg-[#EFF8FF] cursor-pointer"
                    onClick={addWeek}
                  >
                    <PlusIconBold style={{ width: "20px", height: "20px" }} />
                    <div className="text-[#144EA6] text-sm font-medium leading-5 font-outfit">
                      Add Week
                    </div>
                  </div>
                </div>
              )}

              {(hasPermission29 || hasPermission30 || hasPermission32) && (
                <div className="flex items-start rounded-lg">
                  <div
                    className={`flex p-1.5 justify-center items-center gap-2 rounded-md border border-[#DDE5ED] ${
                      isEdited
                        ? "bg-[#F9FBFE] hover:bg-gray-100 cursor-pointer"
                        : "bg-gray-200 text-[#344054]"
                    }`}
                    onClick={() => {
                      isEdited ? setCancelModal(true) : null;
                    }}
                  >
                    <div className="text-[#344054] text-sm font-medium leading-5 font-outfit">
                      Cancel
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className="w-full h-[calc(100vh-100px)] overflow-y-scroll bg-gray-50"
          ref={scrollableDivRef}
        >
          {weeksWithAssigneesAndNames.length > 0 ? (
            <>
              {weeksWithAssigneesAndNames
                .sort(
                  (a, b) =>
                    new Date(a.startDate).getTime() -
                    new Date(b.startDate).getTime()
                )
                .map((week, index) => (
                  <div
                    key={week.id}
                    id={week.id} // Set the ID here
                    ref={(el) => (weekRefs.current[week.week] = el)}
                  >
                    <DisplayWeeks
                      week={week}
                      isLastWeek={
                        index === weeksWithAssigneesAndNames.length - 1
                      }
                      addAssigneeToWeek={addAssigneeToWeek}
                      onDeleteAssignee={handleDeletedAssignees}
                      weeksWithDetails={weeksWithDetails}
                      onSwitchWeeks={switchWeeks} // Call the new switch function
                      isDeleted={deletedAssigneesPayload.includes(week.id)}
                      setIsEdited={setIsEdited}
                    />
                  </div>
                ))}
            </>
          ) : (
            <div key="default-week">
              <DisplayWeeks
                week={createDefaultWeek()}
                isLastWeek={false}
                addAssigneeToWeek={addAssigneeToWeek}
                onDeleteAssignee={handleDeletedAssignees}
                weeksWithDetails={weeksWithDetails}
                isDeleted={true}
                onSwitchWeeks={switchWeeks}
                setIsEdited={setIsEdited}
              />
            </div>
          )}

          {areButtonsVisible && hasPermission29 && (
            <div className=" bg-gray-50">
              {/* Add week */}
              <div className="inline-flex items-start rounded-[8px] mx-[13px] my-[9px]">
                <div
                  className="flex py-[5px] px-[10px] justify-center items-center gap-[8px] rounded-[4px] border border-[#DDE5ED] bg-[#F9FBFE] cursor-pointer hover:bg-gray-50 transition-colors duration-300"
                  onClick={async () => {
                    await addWeek();
                    requestAnimationFrame(() => scrollToBottom()); // Scroll to bottom after the async operation
                  }}
                >
                  <PlusIconBoldBlack
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  ></PlusIconBoldBlack>

                  <div
                    className="text-[#344054] text-[14px] font-medium leading-[20px]"
                    style={{ fontFamily: "Outfit, sans-serif" }}
                  >
                    Add Week
                  </div>
                </div>
              </div>

              {/* Add and Duplicate */}
              <div className="inline-flex items-start rounded-[8px] my-[9px]">
                <div
                  className="flex p-[5px_10px] justify-center items-center gap-[8px] rounded-[4px] cursor-pointer"
                  onClick={async () => {
                    await addAndDuplicate();
                    requestAnimationFrame(() => scrollToBottom()); // Scroll to bottom after the async operation
                  }}
                >
                  <CopyOutlineIcon
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  ></CopyOutlineIcon>

                  <div
                    className="text-[#667085] text-sm font-medium leading-[20px]"
                    style={{ fontFamily: "Outfit, sans-serif" }}
                  >
                    Add & Duplicate
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Spin>

      {projectDateModal && (
        <ProjectDateModal
          onCancel={() => {
            setProjectDateModal(false);
          }}
          visible={projectDateModal}
          startDate={projectDetails?.projectDetails?.startDate}
          endDate={projectDetails?.projectDetails?.endDate}
          onSave={handleDateSave}
        />
      )}
      <EstimatesMissingModal
        onClose={() => setDiscardModal(false)}
        title="Time estimates are missing!"
        description={
          noOfPendingEstimates > 4
            ? `You missed adding time estimates for <span style="font-weight: 500;">${noOfPendingEstimates} weeks</span>. Please add the estimates before the sprint begins.`
            : areWeeksConsecutive(firstFewPendingEstimates) &&
              firstFewPendingEstimates?.length > 1
            ? `You missed adding the time estimates for <span style="font-weight: 500;">${
                firstFewPendingEstimates[0]
              } to ${
                firstFewPendingEstimates[firstFewPendingEstimates.length - 1]
              }</span>. Please add the estimates before the sprint begins.`
            : `You missed adding the time estimates for <span style="font-weight: 500;">${firstFewPendingEstimates.join(
                ", "
              )}</span>. Please add the estimates before the sprint begins.`
        }
        buttonOneText="I’ll Do It Later"
        buttonTwoText="Add Time Estimates"
        onButtonOneClick={async () => {
          setDiscardModal(false);
          await saveChanges();
        }}
        onButtonTwoClick={() => {
          setDiscardModal(false);
        }}
        visible={discardModal}
      />

      <EstimatesMissingModal
        onClose={() => setCancelModal(false)}
        title="Cancel process"
        description="Are you sure you want to cancel? You will lose all data.                                        "
        buttonOneText="Cancel"
        buttonTwoText="Yes, Proceed"
        onButtonOneClick={async () => {
          setCancelModal(false);
        }}
        onButtonTwoClick={() => {
          navigate(`/time-estimates/`);
          setCancelModal(false);
        }}
        visible={cancelModal}
      />

      <EstimatesMissingModal
        onClose={() => setDateChangeModalOne(false)}
        title="Week numbers may vary!"
        description="You are changing the dates of this project. This will affect the project week numbers & existing time estimates. Are you sure you want to proceed?"
        buttonOneText="Cancel"
        buttonTwoText="Yes, proceed"
        onButtonOneClick={() => {
          setDateChangeModalOne(false);
        }}
        onButtonTwoClick={async () => {
          setProjectStartDate(tempStartDate);
          setProjectEndDate(tempEndDate);
          // setWeeksWithAssigneesAndNames(apiPayload.newWeeks);
          setDateChangeModalOne(false);
          await handleApiCalls(apiPayload, true);
        }}
        visible={dateChangeModalOne}
      />

      <EstimatesMissingModal
        onClose={() => setDateChangeModalTwo(false)}
        title="Week numbers may vary!"
        description="You are changing the dates of this project. This will affect the project week numbers. <br/> <br/> Do you want to delete existing estimates/weeks or retain existing estimates & delete weeks manually?"
        buttonOneText="Delete Estimates"
        buttonTwoText="Retain Estimates"
        onButtonOneClick={async () => {
          setDateChangeModalThree(true);
          setDateChangeModalTwo(false);
        }}
        onButtonTwoClick={async () => {
          setProjectStartDate(tempStartDate);
          setProjectEndDate(tempEndDate);
          setDateChangeModalTwo(false);
          await handleApiCalls(apiPayload, false);
        }}
        visible={dateChangeModalTwo}
      />

      <EstimatesMissingModal
        onClose={() => setDateChangeModalThree(false)}
        title="Week numbers may vary!"
        description="This action will automatically delete the estimates for the week(s) no longer in the project duration. Project week numbers will be adjusted accordingly."
        buttonOneText="Cancel"
        buttonTwoText="Confirm"
        onButtonOneClick={async () => {
          setDateChangeModalThree(false);
        }}
        onButtonTwoClick={async () => {
          setDateChangeModalThree(false);
          await handleApiCalls(apiPayload, true);
        }}
        visible={dateChangeModalThree}
      />
    </CustomLayout>
  );
};
export default ProjectTimeEstimatesScreen;
