import React, { useEffect, useState } from "react";
import PageTitle from "../PageTitle";
import { Switch } from "antd";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomSelect from "../../../Core/CommonV2/CustomSelect";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import getUsersName from "../../../Core/utils/getUserName";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import { PlusIcon } from "../../../Core/svgV2/PlusIcon";
import ProjectsSettingsWrapper from "../ProjectsSettingsWrapper";
import { client } from "../../../Core/utils/axiosClient";
import { useSearchParams } from "react-router-dom";
import { getProjectDetatis } from "../../../Core/redux/api/projectAPI";
import {
  getAllAutomation,
  getDefaultNavigation,
} from "../../../Core/redux/api/settingsAPI";
import usePermission from "../../../Core/hooks/usePermission";

const AutomationRow = ({ automation, disabled }) => {
  const [searchParams] = useSearchParams();
  const { userList } = useAppSelector((state) => state.overview);
  const projectId = searchParams.get("projectId");
  const dispatch = useAppDispatch();

  const [currentAutomation, setCurrentAutomation] = useState(automation);

  useEffect(() => {
    setCurrentAutomation(automation);
  }, [automation]);

  const typeList = [
    {
      id: "1",
      name: "Task",
      icon: "/images/v2/schedule/Task.svg",
    },
    {
      id: "3",
      name: "Bug",
      icon: "/images/v2/schedule/Bug.svg",
    },
    { id: "4", name: "New Scope", icon: "/images/v2/schedule/New scope.svg" },
  ];

  const userType = [
    {
      name: "Assignee",
    },
    {
      name: "Reporter",
    },
  ];

  const removeAutomation = async () => {
    try {
      await client.delete("/settings/delete-automation", {
        params: {
          id: automation.id,
        },
      });
      dispatch(getAllAutomation({ projectId }));
    } catch (err) {
      console.log(err);
    }
  };

  console.log(automation, userList, "Automation");

  const updateAutomation = async (key, value) => {
    setCurrentAutomation((prevAutomation) => {
      const newAutomation = { ...prevAutomation, [key]: value };
      return newAutomation;
    });

    try {
      await client.put("/settings/update-automation", {
        [key]: value,
        automationId: automation.id,
      });
      dispatch(getAllAutomation({ projectId }));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="flex gap-x-3 items-center group">
      <div className="mr-3 min-w-5 flex items-center h-5">
        <button className="group-hover:block hidden" onClick={removeAutomation}>
          <CrossIcon className="text-gray-500" />
        </button>
      </div>
      <div className="out-300-14 text-gray-500 whitespace-nowrap">Type is</div>
      <CustomSelect
        placeholder="Select Type"
        disabled={disabled}
        value={currentAutomation.goalType}
        options={typeList.map((title) => {
          return {
            id: title.id,
            value: title.id,
            label: title.name,
            render: () => (
              <div className="flex items-center h-full">
                <img src={title.icon} className="h-5 w-5" />
                <p className="out-300-12 pl-2 text-gray-900 capitalize ">
                  {title.name}
                </p>
              </div>
            ),
          };
        })}
        onChange={(val) => updateAutomation("goalType", val)}
      />
      <CustomSelect
        placeholder="Assignee"
        disabled={disabled}
        value={currentAutomation.fieldName}
        options={userType.map((type) => {
          return {
            id: type.name,
            value: type.name,
            label: type.name,
            render: () => (
              <div className="flex items-center h-full ">
                <p className="out-300-12  text-gray-900 capitalize ">
                  {type.name}
                </p>
              </div>
            ),
          };
        })}
        onChange={(val) => updateAutomation("fieldName", val)}
      />
      <div className="out-300-14 text-gray-500">is</div>
      <CustomSelect
        placeholder="User"
        disabled={disabled}
        value={currentAutomation.value}
        options={userList.map((user) => {
          return {
            id: user.userId,
            value: user.userId,
            label: getUsersName(user),
            render: () => (
              <div className="flex items-center h-full ">
                <CustomAvatar
                  title={getUsersName(user)}
                  src={user.profilePicture}
                  size={24}
                  fontSize={"10px"}
                  whiteText
                  color={user.profilePictureColorCode}
                />
                <p className="out-300-12 pl-2 text-gray-900 capitalize ">
                  {getUsersName(user)}
                </p>
              </div>
            ),
          };
        })}
        onChange={(val) => updateAutomation("value", val)}
      />
    </div>
  );
};

const Preferences = () => {
  const [searchParams] = useSearchParams();
  const { user } = useAppSelector((state) => state.userDetails);
  const { automations, defaultNavigation } = useAppSelector(
    (state) => state.settings
  );
  const dispatch = useAppDispatch();
  const projectId = searchParams.get("projectId");
  const { projectDetails } = useAppSelector((state) => state.overview);
  const { hasPermission } = usePermission("2");

  const pages = [
    {
      id: 1,
      name: "Roadmap",
    },
    {
      id: 2,
      name: "Planner",
    },
    {
      id: 3,
      name: "Sprints",
    },
    {
      id: 4,
      name: "Report",
    },
  ];

  const [isAutoMationEnabled, setIsAutoMationEnabled] = useState(
    projectDetails.automations
  );

  const [autoMationLoading, setAutoMationLoading] = useState(false);
  const [navigationLoading, setNavigationLoading] = useState(false);

  useEffect(() => {
    setIsAutoMationEnabled(projectDetails.automations);
  }, [projectDetails]);

  useEffect(() => {
    if (projectId) {
      dispatch(getDefaultNavigation({ userId: user.id }));
      dispatch(getAllAutomation({ projectId }));
    }
  }, [searchParams, projectId]);

  const handleEnableAutoMation = async (e) => {
    setAutoMationLoading(true);
    try {
      await client.put("/settings/enable-automations", {
        projectId,
        isEnabled: e,
      });
      setIsAutoMationEnabled(true);
      dispatch(getProjectDetatis({ projectId }));
    } catch (err) {
      console.log(err);
    }

    setAutoMationLoading(false);
  };

  const handleEnableAndUpdateNavigation = async (
    screenId,
    isEnabled = defaultNavigation.isEnabled
  ) => {
    setNavigationLoading(true);
    try {
      const body: any = { isEnabled: isEnabled, screenId };

      if (defaultNavigation.id) {
        body.id = defaultNavigation.id;
      }

      await client.post("/settings/enable-navigation", body);
      setIsAutoMationEnabled(true);
      dispatch(getDefaultNavigation({ userId: user.id }));
    } catch (err) {
      console.log(err);
    }

    setNavigationLoading(false);
  };

  const createNewAutomation = async () => {
    try {
      await client.post("/settings/create-automation", {
        projectId,
        fieldName: "Assignee",
      });
      dispatch(getAllAutomation({ projectId }));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="pb-40 max-w-[667px]">
      <PageTitle
        title={"Preferences"}
        subtext={"Manage automation and navigation settings."}
      />
      <ProjectsSettingsWrapper>
        <div className="mt-6 flex-col flex gap-y-6">
          {hasPermission && (
            <>
              <div className="out-500-16 text-black">Automation</div>

              <div className="flex flex-col gap-y-6">
                <div className="flex gap-x-2">
                  <Switch
                    size={"small"}
                    className={`${
                      isAutoMationEnabled ? "afterSwitch" : "beforeSwitch"
                    }`}
                    checked={isAutoMationEnabled}
                    loading={autoMationLoading}
                    onChange={(e) => handleEnableAutoMation(e)}
                  />
                  <div>
                    <p className="out-500-14 text-gray-700">
                      Enable automations
                    </p>
                    <p className="out-300-12 text-gray-500">
                      Set automations for your tasks and bugs in Schedule
                    </p>
                  </div>
                </div>

                {automations.map((automation) => (
                  <AutomationRow
                    key={automation.id}
                    automation={automation}
                    disabled={!isAutoMationEnabled}
                  />
                ))}
                {automations.length < 6 && (
                  <button
                    className="flex gap-x-2 "
                    onClick={createNewAutomation}
                  >
                    <PlusIcon className="text-gray-500" />
                    <p className="out-500-14 text-gray-500">
                      Add {automations.length > 0 && "more"}
                    </p>
                  </button>
                )}
              </div>
            </>
          )}

          <div className="border-solid border-gray-200 relative w-full h-px border-t" />
          <div className="out-500-16 text-black">Navigation</div>
          <div className="flex justify-between gap-x-[78px]">
            <div className="flex gap-x-2">
              <Switch
                size={"small"}
                className={`${
                  defaultNavigation.isEnabled ? "afterSwitch" : "beforeSwitch"
                }`}
                checked={defaultNavigation.isEnabled}
                onChange={(val) => handleEnableAndUpdateNavigation(1, val)}
                loading={navigationLoading}
              />
              <div>
                <p className="out-500-14 text-gray-700">Default project view</p>
                <p className="out-300-12 text-gray-500 whitespace-nowrap">
                  Select a default page when you open a project
                </p>
              </div>
            </div>

            <CustomSelect
              placeholder="Screen"
              disabled={!defaultNavigation.isEnabled}
              value={
                defaultNavigation.screenId ? +defaultNavigation.screenId : null
              }
              options={pages.map((type) => {
                return {
                  id: type.name,
                  value: type.id,
                  label: type.name,
                  render: () => (
                    <div className="flex items-center h-full ">
                      <p className="out-300-12  text-gray-900 capitalize ">
                        {type.name}
                      </p>
                    </div>
                  ),
                };
              })}
              onChange={(e) => handleEnableAndUpdateNavigation(e)}
            />
          </div>
        </div>
      </ProjectsSettingsWrapper>
    </div>
  );
};

export default Preferences;
